import {
  CreateNewDisciplineDto,
  EditNewDisciplineDto,
  EImportSource,
  EProvincialCommittee,
  ICompetition,
  IDisciplineMetadata,
  IEventsMetadata,
  IRegistrationDetail,
  IRegistrationNewPricing,
  IRegistrationPricing,
  IResult,
  IRuleDto,
  PrismaPaymentOption as PaymentOption,
  PrismaDisciplineOrigin,
  Roles,
  PrismaValidation as Validation,
} from '@beathletics/api-interfaces';
import type { CompetitionValidation, LocalTeams, NewCompetition } from '@prisma/client';

const apiTag = '[New Competition API] ';

export class CreateNewCompetition {
  static readonly type = apiTag + 'Create new competition';
  constructor(
    public competition: Partial<Omit<NewCompetition, 'startDate' | 'endDate'>> & { startDate: string; endDate: string },
    public organizer: { abbr: string; type: 'club' | 'federation' },
    public venueId?: string,
  ) {}
}

export class UpdateCompetitionData {
  static readonly type = apiTag + 'Update competition';
  constructor(
    public id: string,
    public data: {
      competition: Partial<Omit<NewCompetition, 'startDate' | 'endDate'>> & {
        startDate: string;
        endDate: string;
      };
      organizer?: { abbr: string; type: 'club' | 'federation' };
      venueId?: string;
    },
  ) {}
}

export class AddDiscipline {
  static readonly type = apiTag + 'Add new discipline';
  constructor(public discipline: CreateNewDisciplineDto) {}
}

export class EditDiscipline {
  static readonly type = apiTag + 'Edit discipline';
  constructor(public discipline: EditNewDisciplineDto) {}
}

export class DeleteDiscipline {
  static readonly type = apiTag + 'Delete discipline';
  constructor(
    public disciplineId: string,
    public eventNumber: string,
  ) {}
}

export class SaveCompetitionData {
  static readonly type = apiTag + 'Save competition metadata';
  constructor(
    public eventNumber: string,
    public data: Partial<ICompetition>,
  ) {}
}

export class SelectOrLoadCompetition {
  static readonly type = apiTag + 'Select or load competition';
  constructor(
    public eventNumber: string,
    public forceLoading = false,
  ) {}
}

export class EditResultData {
  static readonly type = apiTag + 'Edit result data';
  constructor(
    public eventNumber: string,
    public editedResult: IResult,
    public recalculateRanks: boolean,
    public disciplineParentId?: string | null,
  ) {}
}

export class CreateResult {
  static readonly type = apiTag + 'Create result';
  constructor(
    public eventNumber: string,
    public result: Omit<IResult, 'id'>,
    public athletes: {
      id: string;
      order: number | null;
      bib: number;
      clubId?: string;
      category: string;
    }[],
    public recalculateRanks: boolean,
    public children?: Omit<IResult, 'id'>[],
  ) {}
}

export class DeleteEventResult {
  static readonly type = apiTag + 'Delete event result';
  constructor(
    public eventNumber: string,
    public resultId: string,
    public recalculateRanks: boolean,
  ) {}
}

export class EditDisciplineEventType {
  static readonly type = apiTag + 'Edit discipline event type';
  constructor(
    public disciplineId: string,
    public eventTypeId: string,
    public parentId?: string,
  ) {}
}

export class UpdateResultsStatus {
  static readonly type = apiTag + 'Update results validation status';
  constructor(
    public status: Validation,
    public resultIds: string[],
  ) {}
}

export class DeleteDisciplinesAndResults {
  static readonly type = apiTag + 'Delete all disciplines & results';
  constructor(
    public eventNumber: string,
    public autoClearExisting = false,
    public deleteFromError = false,
    public disciplineOrigin?: PrismaDisciplineOrigin,
  ) {}
}

export class DeleteFullCompetition {
  static readonly type = apiTag + 'Delete full competition';
  constructor(public eventNumber: string) {}
}

export class AddOfficialForCompetition {
  static readonly type = apiTag + 'Add official for competition';
  constructor(
    public eventNumber: string,
    public official: {
      email: string;
      firstName: string;
      lastName: string;
      role: Roles.Secretary | Roles.Ja | Roles.Homologation;
    },
  ) {}
}

export class DeleteContactOfCompetition {
  static readonly type = apiTag + 'Delete contact of competition';
  constructor(
    public eventNumber: string,
    public contactId: string,
  ) {}
}

export class ProcessResultFileUpload {
  static readonly type = apiTag + 'Process result file upload';
  constructor(
    public eventNumber: string,
    public file: File,
    public source: EImportSource,
    public registrationSchedule = false,
  ) {}
}

export class ReloadCompetitionLogs {
  static readonly type = apiTag + 'Reload competition logs';
  constructor(public eventNumber: string) {}
}

export class SaveRegistrationDetails {
  static readonly type = apiTag + 'Save registration details';
  constructor(public registrationDetails: IRegistrationDetail & { toEdit?: boolean; toSave?: boolean }) {}
}

export class SavePaymentOption {
  static readonly type = apiTag + 'Save payment option';
  constructor(
    public competitionId: string,
    public option: PaymentOption,
  ) {}
}

export class SavePricing {
  static readonly type = apiTag + 'Save pricing';
  constructor(public pricing: IRegistrationNewPricing | IRegistrationPricing) {}
}

export class DeleteRegistrationPricing {
  static readonly type = apiTag + 'Delete registration pricing';
  constructor(public pricingId: string) {}
}

export class AddAthleteOrClubAsVip {
  static readonly type = apiTag + 'Add athlete or club as VIP';
  constructor(
    public data: {
      competitionId: string;
      athleteId?: string;
      clubId?: string;
    },
  ) {}
}

export class RemoveVip {
  static readonly type = apiTag + 'Remove VIP';
  constructor(public vipId: string) {}
}

export class SaveCustomRegistrationRule {
  static readonly type = apiTag + 'Save custom registration rule';
  constructor(public data: IRuleDto) {}
}

export class DeleteRegistrationRule {
  static readonly type = apiTag + 'Delete registration rule';
  constructor(public ruleId: string) {}
}

export class ResetShop {
  static readonly type = apiTag + 'Reset shop';
  constructor(public eventNumber: string) {}
}

export class ReSyncShop {
  static readonly type = apiTag + 'ReSync shop';
  constructor(public eventNumber: string) {}
}

export class SaveLocalTeamsList {
  static readonly type = apiTag + 'Save local teams list';
  constructor(
    public competitionId: string,
    public teams: LocalTeams[],
  ) {}
}

export class LoadDisciplineLiveResults {
  static readonly type = apiTag + 'Load discipline live results';
  constructor(
    public eventNumber: string,
    public disciplineIdAM: number,
  ) {}
}

export class ReplaceCompetitionMetadata {
  static readonly type = apiTag + 'Replace competition data';
  constructor(
    public eventNumber: string,
    public data: IEventsMetadata,
  ) {}
}

export class PushUpdatedData {
  static readonly type = apiTag + 'Push updated data';
  constructor(
    public eventNumber: string,
    public data: {
      results: IResult[];
      disciplines: { id: string; metadata: IDisciplineMetadata }[];
    },
  ) {}
}

export class GenerateSyncCode {
  static readonly type = apiTag + 'Generate sync code';
  constructor(public eventNumber: string) {}
}

export class LoadEventsForValidation {
  static readonly type = apiTag + 'Load events for validation';
  constructor(public committee?: EProvincialCommittee | 'ADMIN') {}
}

export class UpdateCompetitionValidation {
  static readonly type = apiTag + 'Update competition validation';
  constructor(
    public status: CompetitionValidation,
    public eventNumbers: string[],
  ) {}
}

export class RemoveCategoryFromDiscipline {
  static readonly type = apiTag + 'Remove category from discipline';
  constructor(
    public eventNumber: string,
    public disciplineId: string,
    public categoryId: string,
  ) {}
}

export class AddCategoriesToDiscipline {
  static readonly type = apiTag + 'Add category to discipline';
  constructor(
    public eventNumber: string,
    public disciplineId: string,
    public categoriesId: string[],
  ) {}
}

export class SaveCompetitionSettings {
  static readonly type = apiTag + 'Save competition settings';
  constructor(
    public eventNumber: string,
    public settings: { hideSchedule: boolean },
  ) {}
}
