import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  CreateNewDisciplineDto,
  EditNewDisciplineDto,
  EImportSource,
  EProvincialCommittee,
  ICompetition,
  IDiscipline,
  IEventRegistration,
  ILastSeasonBest,
  IRegistrationDetail,
  IRegistrationNewPricing,
  IRegistrationPricing,
  IRegistrationVip,
  IResult,
  IRuleDto,
  PrismaDisciplineOrigin,
  Roles,
} from '@beathletics/api-interfaces';
import type {
  BusinessLog,
  CompetitionValidation,
  LocalTeams,
  NewCategory,
  NewCompetition,
  PaymentOption,
  RegistrationsRules,
} from '@prisma/client';
import { EnvService } from '@beathletics/auth';

@Injectable({
  providedIn: 'root',
})
export class NewCompetitionApiService {
  #http = inject(HttpClient);
  #env = inject(EnvService);
  apiUrl = this.#env.getEnv('API_URL','/api');

  // => GET

  getFullCompetitionByEventNumber = (eventNumber: string) =>
    this.#http.get<{
      competition: ICompetition;
      results: IResult[];
      logs: BusinessLog[];
      previousSb: ILastSeasonBest[];
    }>(`${this.apiUrl}/new-competition/full/${eventNumber}`);

  getCompetitionLogs = (eventNumber: string) =>
    this.#http.get<BusinessLog[]>(`${this.apiUrl}/new-competition/logs/${eventNumber}`);

  getIfEventNumberExists(eventNumber: string) {
    return this.#http.get<boolean>(`${this.apiUrl}/new-competition/event-number/${eventNumber}`);
  }

  reSyncShop = (eventNumber: string) => this.#http.get(`${this.apiUrl}/shopManagement/product/resync/${eventNumber}`);

  getCompetitionsToValidate = (committee: EProvincialCommittee | 'ADMIN') =>
    this.#http.get<ICompetition[]>(`${this.apiUrl}/new-competition/validation/${committee}`);

  // => POST

  createCompetition(
    competition: Partial<Omit<NewCompetition, 'startDate' | 'endDate'>> & {
      startDate: string;
      endDate: string;
    },
    organizer: { abbr: string; type: 'club' | 'federation' },
    venueId?: string,
  ) {
    return this.#http.post<ICompetition>(`${this.apiUrl}/new-competition/create`, {
      competition,
      organizer,
      venueId,
    });
  }

  updateCompetition(
    id: string,
    data: {
      competition: Partial<Omit<NewCompetition, 'startDate' | 'endDate'>> & {
        startDate: string;
        endDate: string;
      };
      organizer?: { abbr: string; type: 'club' | 'federation' };
      venueId?: string;
    },
  ) {
    return this.#http.post<ICompetition>(`${this.apiUrl}/new-competition/${id}`, data);
  }

  createNewResult(
    eventNumber: string,
    result: Omit<IResult, 'id'>,
    athletes: {
      id: string;
      order: number | null;
      bib: number;
      clubId?: string;
      category: string;
    }[],
    recalculateRanks: boolean,
    children?: Omit<IResult, 'id'>[],
  ) {
    return this.#http.post<{
      results: IResult[];
      updatedRanks: { id: string; rank: number }[];
    }>(`${this.apiUrl}/new-competition/${eventNumber}/result`, {
      result,
      athletes,
      recalculateRanks,
      children,
    });
  }

  uploadResultFile(eventNumber: string, file: File, source: EImportSource, registrationSchedule = false) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('eventNumber', eventNumber);
    return this.#http.post<{ competition: ICompetition; results: IResult[] }>(
      `${this.apiUrl}/new-competition/${eventNumber}/upload/${source}/${registrationSchedule ? 'schedule' : 'results'}`,
      formData,
    );
  }

  saveRegistrationDetails(details: IRegistrationDetail & { toEdit?: boolean; toSave?: boolean }) {
    return this.#http.post<IRegistrationDetail>(`${this.apiUrl}/new-competition/registrations/details`, details);
  }

  addAthleteOrClubAsVip(data: { competitionId: string; athleteId?: string; clubId?: string }) {
    return this.#http.post<IRegistrationVip>(`${this.apiUrl}/new-competition/registrations/vip`, data);
  }

  saveRegistrationCustomRule(data: IRuleDto) {
    return this.#http.post<IRuleDto>(`${this.apiUrl}/new-competition/registrations/rule`, data);
  }

  saveCompetitionData(eventNumber: string, data: Partial<ICompetition>) {
    return this.#http.post<ICompetition>(`${this.apiUrl}/new-competition/${eventNumber}/data`, { data });
  }

  saveLocalTeamsList(competitionId: string, teams: LocalTeams[]) {
    return this.#http.post<LocalTeams[]>(`${this.apiUrl}/new-competition/registrations/teams`, { competitionId, teams });
  }

  generateLiveSyncCode = (eventNumber: string) =>
    this.#http.post<{ syncCode: string }>(`${this.apiUrl}/new-competition/${eventNumber}/live-sync-code`, {});

  saveRegistrationPricing = (pricing: IRegistrationNewPricing | IRegistrationPricing) =>
    this.#http.post<IRegistrationPricing>(`${this.apiUrl}/new-competition/registrations/pricing`, pricing);

  // => PUT

  saveRegistrationPaymentOption = (competitionId: string, option: PaymentOption) =>
    this.#http.put(`${this.apiUrl}/new-competition/registrations/payment-option`, { competitionId, option });

  updateResultData = (
    eventNumber: string,
    editedResult: IResult,
    recalculateRanks: boolean,
    disciplineParentId?: string | null,
  ) =>
    this.#http.put<{
      treatedResult: IResult;
      recalculatedRanks: { id: string; rank: number }[];
      totalRecalculated?: IResult;
    }>(`${this.apiUrl}/new-competition/result`, {
      eventNumber,
      editedResult,
      recalculateRanks,
      disciplineParentId,
    });

  updateResultsValidation = (status: string, resultIds: string[]) =>
    this.#http.put<boolean>(`${this.apiUrl}/new-competition/results/validation`, {
      status,
      resultIds,
    });

  updateCompetitionValidation = (status: CompetitionValidation, eventNumbers: string[]) =>
    this.#http.put<boolean>(`${this.apiUrl}/new-competition/validation`, {
      status,
      eventNumbers,
    });

  updateDisciplineEventType = (disciplineId: string, eventTypeId: string) =>
    this.#http.put<IDiscipline>(`${this.apiUrl}/new-competition/discipline/edit-event-type`, { disciplineId, eventTypeId });

  linkOfficialWithCompetition(
    eventNumber: string,
    official: {
      email: string;
      firstName: string;
      lastName: string;
      role: Roles.Secretary | Roles.Ja | Roles.Homologation;
    },
  ) {
    return this.#http.post<ICompetition>(`${this.apiUrl}/new-competition/${eventNumber}/official`, official);
  }

  removeCatFromDiscipline(disciplineId: string, categoryId: string) {
    return this.#http.put<IDiscipline>(`${this.apiUrl}/discipline/remove-category`, {
      disciplineId,
      categoryId,
    });
  }

  addCatToDiscipline(disciplineId: string, categoriesId: string[]) {
    return this.#http.put<NewCategory[]>(`${this.apiUrl}/discipline/add-category`, {
      disciplineId,
      categoriesId,
    });
  }

  // => DELETE

  removeContactOfCompetition(eventNumber: string, contactId: string) {
    return this.#http.delete<ICompetition>(`${this.apiUrl}/new-competition/${eventNumber}/official/${contactId}/delete`);
  }

  deleteResult = (eventNumber: string, resultId: string, recalculateRanks: boolean) =>
    this.#http.delete<{
      updatedRanks: { id: string; rank: number }[];
      childrenIds: string[];
    }>(`${this.apiUrl}/new-competition/${eventNumber}/result/${resultId}`, {
      body: { recalculateRanks },
    });

  deleteAllDisciplinesAndResults = (
    eventNumber: string,
    autoClearExisting = false,
    deleteFromError = false,
    disciplineOrigin?: PrismaDisciplineOrigin,
  ) =>
    this.#http.delete(`${this.apiUrl}/new-competition/${eventNumber}/disciplines/delete`, {
      body: { autoClearExisting, deleteFromError, disciplineOrigin },
    });

  deleteFullCompetition = (eventNumber: string) => this.#http.delete(`${this.apiUrl}/new-competition/${eventNumber}/delete`);

  removeVipFromRegistration = (vipId: string) =>
    this.#http.delete<IRegistrationVip>(`${this.apiUrl}/new-competition/registrations/vip/remove/${vipId}`);

  getRegistrationsForEvent = (eventNumber: string) =>
    this.#http.get<IEventRegistration[]>(`${this.apiUrl}/shopManagement/orders/${eventNumber}`);

  removeCustomRuleFromRegistration = (ruleId: string) =>
    this.#http.delete<RegistrationsRules>(`${this.apiUrl}/new-competition/registrations/rule/remove/${ruleId}`);

  resetShop = (eventNumber: string) => this.#http.delete(`${this.apiUrl}/shopManagement/product/${eventNumber}`);

  deleteRegistrationPricing = (pricingId: string) =>
    this.#http.delete(`${this.apiUrl}/new-competition/registrations/pricing/${pricingId}`);

  addDiscipline = (discipline: CreateNewDisciplineDto) =>
    this.#http.post<{disciplines: IDiscipline[]}>(`${this.apiUrl}/discipline/add`, discipline);

  editDiscipline = (discipline: EditNewDisciplineDto) =>
    this.#http.put<IDiscipline>(`${this.apiUrl}/discipline/edit/${discipline.id}`, discipline);


  deleteDiscipline = (disciplineId: string) => this.#http.delete<IDiscipline>(`${this.apiUrl}/discipline/${disciplineId}`);

  saveCompetitionSettings = (eventNumber: string, settings: { hideSchedule: boolean }) =>
    this.#http.put<Partial<ICompetition>>(`${this.apiUrl}/new-competition/${eventNumber}/settings`, { settings });
}
